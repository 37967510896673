<template>
  <div>
    <v-main style="margin-top: 20px">
      <v-container fluid>
        <v-card class="mx-auto" outlined elevation="2">
          <v-card-title>
            Listă de utilizatori
            <v-spacer />

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Caută un utilizator.."
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="users"
            :search="search"
            class="elevation-1"
            multi-sort
            item-key="Id"
            dense
            no-data-text="Niciun utilizator disponibil"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': 'Utilizatori pe pagină',
              'items-per-page-options': [20, 50, 100],
            }"
          >
            <template v-slot:[`item.actiuni`]="{ item }">
              <v-tooltip bottom dark>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="editItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="success"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t("editeazautilizatorul") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>

        <v-card class="mx-auto mt-4" outlined elevation="2">
          <v-card-title>
            Comenzi clienţi
            <v-spacer />

            <v-text-field
              v-model="search1"
              append-icon="mdi-magnify"
              label="Caută o comandă.."
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>

          <v-data-table
            :headers="headers1"
            :items="users1"
            :search="search1"
            class="elevation-1"
            multi-sort
            item-key="Id"
            dense
            no-data-text="Nicio comandă disponibilă"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              'items-per-page-text': 'Comenzi pe pagină',
              'items-per-page-options': [20, 50, 100],
            }"
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                color="red"
                text-color="white"
                v-if="item.status == 0"
                small
              >
                Nerezolvat
              </v-chip>

              <v-chip color="success" small v-else> Rezolvat </v-chip>
            </template>

            <template v-slot:[`item.Actiuni`]="{ item }">
              <v-tooltip bottom dark>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click="viewItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                  >
                    mdi-information-outline
                  </v-icon>
                </template>
                <span>{{ $t("verificacomanda") }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>

        <v-dialog v-model="dialog" max-width="600" persistent>
          <v-card>
            <v-card-title class="pb-4">
              <span class="text-h5 mr-2">Editează utilizatorul </span>
              <span class="text-h5 blue--text" style="color: red">
                {{ editedItem.email }}</span
              >
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-form ref="form" lazy-validation v-model="valid">
                  <v-row>
                    <v-col cols="12" sm="6" md="6" class="coloana">
                      <Input
                        v-model="editedItem.MGM_usages"
                        :label="$t('mgmusages')"
                        :rules="[
                          (v) =>
                            Number.isInteger(Number(v)) || $t('numarintreg'),
                          (v) => v >= 0 || $t('numarpozitiv'),
                          (v) => !!v.toString() || $t('campobligatoriu'),
                        ]"
                      />
                    </v-col>

                    <v-col cols="12" sm="6" md="6" class="coloana">
                      <Input
                        v-model="editedItem.centralized_usages"
                        :label="$t('centralizedusages')"
                        :rules="[
                          (v) =>
                            Number.isInteger(Number(v)) || $t('numarintreg'),
                          (v) => v >= 0 || $t('numarpozitiv'),
                          (v) => !!v.toString() || $t('campobligatoriu'),
                        ]"
                      />
                    </v-col>

                    <v-col cols="12" sm="6" md="6" class="coloana">
                      <Input
                        v-model="editedItem.comparative_usages"
                        :label="$t('comparativeusages')"
                        :rules="[
                          (v) =>
                            Number.isInteger(Number(v)) || $t('numarintreg'),
                          (v) => v >= 0 || $t('numarpozitiv'),
                          (v) => !!v.toString() || $t('campobligatoriu'),
                        ]"
                      />
                    </v-col>

                    <v-col cols="12" sm="6" md="6" class="coloana">
                      <v-select
                        v-model="editedItem.role.Name"
                        dense
                        outlined
                        :label="$t('selecteazarolul')"
                        :items="roluri"
                        :rules="[(v) => !!v || $t('campobligatoriu')]"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Anulează
              </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Salvează </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogcomanda" max-width="700">
          <v-card>
            <v-card-title class="pb-4">
              <span class="text-h5 mr-2">Vizualizează comanda lui</span>
              <span class="text-h5 blue--text" style="color: red">
                {{ vieweditem.email }}</span
              >
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row class="d-flex">
                  <v-col cols="12" md="12" style="padding-bottom: 0">
                    <h1 class="my-4">
                      Informaţii comandă
                      <v-chip
                        color="red"
                        text-color="white"
                        v-if="vieweditem.status == 0"
                        small
                      >
                        Nerezolvată
                      </v-chip>

                      <v-chip color="success" small v-else> Rezolvată </v-chip>
                    </h1>
                    Număr comandă: {{ vieweditem.Id }}<br />
                    Data comenzii: {{ schimbanume(vieweditem.date) }}<br />
                    Servicii comandate: {{ schimbanume(vieweditem.items)
                    }}<br />
                    <span v-if="this.vieweditem.bill_emited_to == 0"
                      >Clientul a preferat factura pe
                      <strong>Persoană fizică</strong></span
                    >
                    <span v-else
                      >Clientul a preferat factura pe
                      <strong>Persoană juridică</strong></span
                    >
                  </v-col>

                  <v-col cols="12" md="6" style="padding-bottom: 0">
                    <h1 class="my-4">Informaţii cont</h1>
                    Client: {{ vieweditem.name }} (ID:
                    {{ vieweditem["users.Id"] }})<br />
                    CNP: {{ schimbanume(vieweditem.SSN) }}<br />
                    Număr CI: {{ schimbanume(vieweditem.ID_number) }}<br />
                    Serie CI: {{ schimbanume(vieweditem.ID_serial) }}<br />
                    Adresă: {{ schimbanume(vieweditem.address) }}<br />
                    Oraş: {{ schimbanume(vieweditem.city) }}<br />
                    Judeţ: {{ schimbanume(vieweditem.county) }}<br />
                  </v-col>

                  <v-col cols="12" md="6" style="padding-bottom: 0">
                    <h1 class="my-4">Persoană juridică</h1>
                    Companie: {{ schimbanume(vieweditem.company_name) }}<br />
                    CUI: {{ schimbanume(vieweditem.CUI) }}<br />
                    Nr.Reg.Com: {{ schimbanume(vieweditem.nr_reg_com) }}<br />
                    Sediu Social: {{ schimbanume(vieweditem.social_office)
                    }}<br />
                  </v-col>

                  <v-col cols="12" md="4" style="padding-bottom: 0">
                    <v-switch
                      v-model="vieweditem.status"
                      :label="`Tichet: ${getswitchtext(vieweditem.status)}`"
                      @change="onTicketStatusChange(vieweditem)"
                    ></v-switch>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeorder">
                Ieşi
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { HTTP } from "../../utils/http-common";
import Input from "../../components/inputs/Input.vue";
import constants from "../../utils/constants.js";
export default {
  name: "Admin",
  components: { Input },
  data: () => ({
    /* Dragă viitor programator, 
    Aici s-au irosit mulţi neuroni. 
    Dacă te găseşti în poziţia în care trebuie să revizuieşti acest cod, 
    creşte variabila de mai jos
    ca avertisment pentru următorii programatori
    nr_neuroni_irositi = 210.000.000.000 */
    editedItem: {
      role: {
        Name: "",
      },
    },
    editedIndex: -1,
    dialogcomanda: false,
    vieweditem: {},
    loading2: false,
    users1: [],
    search1: "",
    headers1: [
      { text: "Nr", value: "Id", sortable: true },
      { text: "Client", value: "name", sortable: true },
      { text: "Email", value: "email", sortable: true },
      { text: "Data", value: "date", sortable: true },
      { text: "Status", value: "status", sortable: true },
      { text: "Acţiuni", value: "Actiuni", sortable: false },
    ],
    users: [],
    search: "",
    headers: [
      { text: "ID", value: "Id" },
      { text: "Email", value: "email" },
      { text: "MGM", value: "MGM_usages" },
      { text: "Centralizator", value: "centralized_usages" },
      { text: "Comparativ", value: "comparative_usages" },
      { text: "Rol", value: "role.Name", sortable: false },
      { text: "Acţiuni", value: "actiuni", sortable: false },
    ],
    dialog: false,
    roluri: constants.roles,
    valid: true,
    switch1: 0,
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    getswitchtext(text) {
      if (text == 0) return "Nerezolvat";
      else return "Rezolvat";
    },
    schimbanume(text) {
      if (text == null) {
        return "Necompletat";
      } else return text;
    },
    editItem(item) {
      this.dialog = true;
      this.editedIndex = this.users.indexOf(item);
      //this.editedItem = Object.assign({}, item)
      //this.editedItem = {...item};
      this.editedItem = this.deepClone(item);
      //this.editedItem.role.Name = this.salam(item.role.Id);
      //console.log(this.editedItem.role.Name)
    },
    viewItem(item) {
      this.vieweditem = item;
      this.dialogcomanda = true;
    },
    close() {
      this.dialog = false;
    },
    closeorder() {
      this.dialogcomanda = false;
    },
    saveorder() {},
    save() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("Id", this.editedItem.Id);
        var index = 0;
        for (const r of this.roluri) {
          if (r == this.editedItem.role.Name) {
            break;
          }
          index++;
        }
        formData.append("role_id", index + 1);
        formData.append("MGM_usages", parseInt(this.editedItem.MGM_usages));
        formData.append(
          "centralized_usages",
          parseInt(this.editedItem.centralized_usages)
        );
        formData.append(
          "comparative_usages",
          parseInt(this.editedItem.comparative_usages)
        );
        HTTP.put("users", formData, { responseType: "json" }).then((resp) => {
          if (resp["data"]["status"]["code"] == 200) {
            Object.assign(this.users[this.editedIndex], this.editedItem);
          } else if (resp["data"]["status"]["code"] != 200) {
            alert(resp["data"]["status"]["message"]);
          } else {
            alert("A aparut o eroare interna!");
          }
        });

        this.close();
      }
    },
    deepClone(data) {
      var type = typeof data;
      var obj;
      if (type === "array") {
        obj = [];
      } else if (type === "object") {
        obj = {};
      } else {
        //No more next level
        return data;
      }
      if (type === "array") {
        for (var i = 0, len = data.length; i < len; i++) {
          obj.push(this.deepClone(data[i]));
        }
      } else if (type === "object") {
        for (var key in data) {
          obj[key] = this.deepClone(data[key]);
        }
      }
      return obj;
    },
    onTicketStatusChange(item) {
      let formData = new FormData();
      formData.append("order_id", item.Id.toString());
      formData.append("status", item.status ? 1 : 0);
      HTTP.post("status_order", formData, { responseType: "json" }).then(
        (resp) => {
          if (resp["data"]["status"]["code"] == 200) {
            alert("Comanda modificata");
          } else if (resp["data"]["status"]["code"] != 200) {
            alert(resp["data"]["status"]["message"]);
          } else {
            alert("A aparut o eroare interna!");
          }
        }
      );
    },
  },
  beforeCreate() {
    HTTP.get("users", {
      responseType: "json",
    }).then((resp) => {
      if (resp["data"]["status"]["code"] == 200) {
        this.users = resp["data"]["data"];
        this.editedItem = this.deepClone(this.users[0]);
      } else if (resp["data"]["status"]["code"] != 200) {
        alert("S-a produs o eroare interna");
        this.$router.push("/");
      } else {
        alert("S-a produs o eroare interna");
        this.$router.push("/");
      }
    });

    HTTP.get("orders", {
      responseType: "json",
    }).then((resp) => {
      if (resp["data"]["status"]["code"] == 200) {
        this.users1 = resp["data"]["data"];
        this.editedItem = this.deepClone(this.users[0]);
      } else if (resp["data"]["status"]["code"] != 200) {
        alert("S-a produs o eroare interna");
        this.$router.push("/");
      } else {
        alert("S-a produs o eroare interna");
        this.$router.push("/");
      }
    });
  },
};
</script>

<style scoped>
.coloana {
  padding-bottom: 0;
  padding-top: 4px;
}
</style>
