<template>
      <v-btn color="success" v-bind="$attrs">
        {{ label }}
        <template v-slot:loader>
          <span>{{ loadingLabel }}</span>
        </template>
      </v-btn>
</template>

<script>
export default {
  inheritsAttrs: false,
  props: ["label", "loadingLabel"],
  name: "Loaderbutton",
};
</script>

<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>