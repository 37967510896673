<template>
  <div>
    <v-main style="margin-top: 20px">
      <v-container>
        <v-row justify="center">
          <v-col cols="12" class="d-flex flex-column align-center">
            <h5 style="font-weight: 600; font-size: 1.5rem">
              Tipuri de abonamente
            </h5>
          </v-col>

          <v-col cols="12">
            <v-alert dense text type="error" dismissible border="left">
              {{ $t("infopricing") }}
            </v-alert>
          </v-col>

          <v-col cols="12" class="d-flex flex-column">
            <v-radio-group v-model="radios">
              <template v-slot:label>
                <div>Cum doreşti să îţi emitem<strong> factura</strong>?</div>
              </template>
              <v-radio value="0">
                <template v-slot:label>
                  <div>Persoană fizică</div>
                </template>
              </v-radio>
              <v-radio value="1">
                <template v-slot:label>
                  <div>Persoană juridică</div>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>

          <v-col
            v-for="item in items"
            :key="item.name"
            cols="12"
            lg="4"
            class="d-flex"
          >
            <v-card
              elevation="10"
              outlined
              class="pa-8"
              :style="item.deal ? 'border: 4px solid #1976D2;' : ''"
              style="border-radius: 40px; flex: 1 auto"
            >
              <v-layout justify-space-between column fill-height>
                <div>
                  <p class="pricing" v-if="item.pret != 0">
                    {{ item.pret }} Lei <span class="perioada">/ an</span>
                  </p>
                  <p class="mb-1 pricingname">{{ item.name }}</p>
                  <p class="mb-4 pricingsubtitle">{{ item.descriere }}</p>
                </div>

                <div class="beneficii">
                  <div
                    v-for="beneficiu in item.beneficii"
                    :key="beneficiu.name"
                    class="d-flex mb-2"
                  >
                    <v-icon v-if="item.pret != 0" color="success" class="mr-2"
                      >mdi-check-bold</v-icon
                    >
                    <v-icon v-else color="primary" class="mr-2"
                      >mdi-share</v-icon
                    >
                    <p class="pricingcaption">{{ beneficiu.name }}</p>
                  </div>
                </div>

                <div>
                  <v-btn
                    block
                    rounded
                    :outlined="item.deal ? false : true"
                    color="primary"
                    class="mt-4"
                    @click="buy(item)"
                    v-if="item.id != 6"
                  >
                    Continuă
                  </v-btn>

                  <v-btn
                    block
                    rounded
                    :outlined="item.deal ? false : true"
                    color="primary"
                    class="mt-4"
                    @click="configureaza()"
                    v-else
                  >
                    Configurează
                  </v-btn>
                </div>
              </v-layout>
            </v-card>
          </v-col>
        </v-row>

        <v-dialog v-model="dialogconfigurare" persistent max-width="500">
          <v-card>
            <v-card-title class="text-h5 mb-2">
              Configurează-ţi abonamentul
            </v-card-title>
            <v-card-subtitle
              >Alege din lista de mai jos numărul de utilizări pe care le
              doreşti:</v-card-subtitle
            >

            <v-card-text>
              <v-container>
                <v-form ref="form" lazy-validation v-model="valid">
                  <v-row>
                    <v-col cols="12" sm="12" md="12" class="coloana">
                      <Input
                        v-model="mgmusages"
                        :label="$t('mgmusages')"
                        hint="45 Lei / raport"
                        persistent-hint
                        :rules="[
                          (v) =>
                            Number.isInteger(Number(v)) || $t('numarintreg'),
                          (v) => v > 0 || $t('numarpozitiv'),
                          (v) => v < 2147483645 || $t('numarpreamare'),
                          (v) => !!v.toString() || $t('campobligatoriu'),
                        ]"
                      />
                    </v-col>

                    <v-col cols="12" sm="12" md="12" class="coloana">
                      <Input
                        v-model="comparativeusages"
                        :label="$t('comparativeusages')"
                        hint="25 Lei / raport"
                        persistent-hint
                        :rules="[
                          (v) =>
                            Number.isInteger(Number(v)) || $t('numarintreg'),
                          (v) => v > 0 || $t('numarpozitiv'),
                          (v) => v < 2147483645 || $t('numarpreamare'),
                          (v) => !!v.toString() || $t('campobligatoriu'),
                        ]"
                      />
                    </v-col>

                    <v-col cols="12" sm="12" md="12" class="coloana">
                      <Input
                        v-model="centralizedusages"
                        :label="$t('centralizedusages')"
                        hint="35 Lei / raport"
                        persistent-hint
                        :rules="[
                          (v) =>
                            Number.isInteger(Number(v)) || $t('numarintreg'),
                          (v) => v > 0 || $t('numarpozitiv'),
                          (v) => v < 2147483645 || $t('numarpreamare'),
                          (v) => !!v.toString() || $t('campobligatoriu'),
                        ]"
                      />
                    </v-col>
                  </v-row>

                  <div class="mt-4">
                    {{ aratapret() }}
                  </div>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="dialogconfigurare = false"
              >
                Înapoi
              </v-btn>
              <v-btn color="blue darken-1" text @click="buyCustom()">
                Continuă
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import { HTTP } from "../../utils/http-common";
import Input from "../../components/inputs/Input.vue";
export default {
  name: "Pricing",
  components: { Input },
  data: () => ({
    mgmusages: "",
    centralizedusages: "",
    comparativeusages: "",
    stripe: null,
    valid: true,
    total_plata: 0,
    dialogconfigurare: false,
    radios: "0",
    items: [
      {
        id: 1,
        name: "Basic",
        pret: 250,
        descriere: "Valabilitatea pachetului este de 1 an. Preţul include TVA.",
        deal: false,
        beneficii: [{ name: "10 rapoarte MGM" }],
      },
      {
        id: 2,
        name: "Basic Plus",
        pret: 425,
        descriere: "Valabilitatea pachetului este de 1 an. Preţul include TVA.",
        deal: false,
        beneficii: [
          { name: "10 rapoarte MGM" },
          { name: "5 rapoarte comparative" },
        ],
      },
      {
        id: 3,
        name: "Standard",
        pret: 700,
        descriere: "Valabilitatea pachetului este de 1 an. Preţul include TVA.",
        deal: false,
        beneficii: [
          { name: "20 rapoarte MGM" },
          { name: "10 rapoarte comparative" },
        ],
      },
      {
        id: 4,
        name: "Standard Plus",
        pret: 850,
        descriere: "Valabilitatea pachetului este de 1 an. Preţul include TVA.",
        deal: false,
        beneficii: [
          { name: "20 rapoarte MGM" },
          { name: "10 rapoarte comparative" },
          { name: "1 raport centralizator" },
        ],
      },
      {
        id: 5,
        name: "Premium",
        pret: 1250,
        descriere: "Valabilitatea pachetului este de 1 an. Preţul include TVA.",
        deal: false,
        beneficii: [
          { name: "40 rapoarte MGM" },
          { name: "20 rapoarte comparative" },
          { name: "2 raport centralizator" },
        ],
      },
      {
        // ID-ul pentru card-ul cu preţuri individuale trebuie să rămână 6 pentru că este folosit în funcţia de click
        id: 6,
        name: "Preţuri individuale",
        pret: 0,
        descriere:
          "Valabilitatea serviciului este de 1 an. Preţul include TVA.",
        deal: true,
        beneficii: [
          { name: "1 raport MGM: 45 Lei" },
          { name: "1 raport comparativ: 25 Lei" },
          { name: "1 raport centralizator: 35 Lei" },
        ],
      },
    ],
  }),
  methods: {
    aratapret() {
      this.total_plata =
        this.mgmusages * 45 +
        this.comparativeusages * 25 +
        this.centralizedusages * 35;
      if (
        this.mgmusages >= 0 &&
        this.comparativeusages >= 0 &&
        this.centralizedusages >= 0 &&
        this.total_plata >= 1
      ) {
        return "Total de plată: " + this.total_plata + " Lei";
      }
      return "";
    },
    configureaza() {
      this.dialogconfigurare = true;
    },

    buy(item) {
      if (item.id != 6) {
        let formData = new FormData();
        formData.append("package", item.name);
        formData.append("price", item.pret);
        formData.append("quantity", "1");
        formData.append("bill_to", this.radios);
        HTTP.post("checkout", formData, { responseType: "json" }).then(
          (resp) => {
            if (resp["data"]["status"]["code"] == 200) {
              return this.stripe.redirectToCheckout({
                sessionId: resp["data"]["data"]["sessionId"],
              });
            } else if (resp["data"]["status"]["code"] != 200) {
              alert(resp["data"]["status"]["message"]);
            } else {
              alert("A aparut o eroare interna!");
            }
          }
        );
      }
    },
    buyCustom() {
      // var item = this.items[5];
      if (this.total_plata > 0) {
        let formData = new FormData();
        formData.append(
          "package",
          "Raport MGM" +
            "," +
            "Raport Comparativ" +
            "," +
            "Raport Centralizator"
        );
        formData.append("price", 45 + "," + 25 + "," + 35);
        formData.append(
          "quantity",
          this.mgmusages +
            "," +
            this.comparativeusages +
            "," +
            this.centralizedusages
        );
        formData.append("bill_to", this.radios);
        HTTP.post("checkout", formData, { responseType: "json" }).then(
          (resp) => {
            if (resp["data"]["status"]["code"] == 200) {
              return this.stripe.redirectToCheckout({
                sessionId: resp["data"]["data"]["sessionId"],
              });
            } else if (resp["data"]["status"]["code"] != 200) {
              alert(resp["data"]["status"]["message"]);
            } else {
              alert("A aparut o eroare interna!");
            }
          }
        );
      }
    },
  },
  created() {
    HTTP.get("stripe/key").then((resp) => {
      if (resp["data"]["status"]["code"] == 200) {
        this.stripe = window.Stripe(resp["data"]["data"]["publicKey"]);
      } else if (resp["data"]["status"]["code"] != 200) {
        alert(resp["data"]["status"]["message"]);
      } else {
        alert("A aparut o eroare interna!");
      }
    });
  },
};
</script>

<style scoped>
.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}

.pricingname {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  margin: 0;
}

.pricingsubtitle {
  color: #7d8b9d;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  letter-spacing: 0.0333333333em;
  margin: 0;
}

.pricingcaption {
  font-size: 0.75rem;
  letter-spacing: 0.0333333333em;
  font-weight: 500;
  margin: auto 0;
}

.beneficii {
  display: flex;
  flex-direction: column;
}

.pricing {
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1rem;
  letter-spacing: normal;
}

.perioada {
  color: #647283;
  font-size: 0.875rem;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
}

.coloana {
  padding-bottom: 0;
  padding-top: 4px;
}
</style>