<template>

  <v-select
    v-on:input="$emit('input', $event)"
    v-bind="$attrs"
    dense
    outlined
  ></v-select>

</template>

<script>
export default {
  inheritsAttrs: false,
  name: "Dropdown",
};
</script>
