export default {
  genders: ["Masculin", "Feminin"],
  MGMSports: [
    "Aerobic",
    "Aeromodelism",
    "Aikido",
    "Alpinism",
    "Altul",
    "Atletism",
    "Arte marțiale",
    "Automobilism",
    "Badminton",
    "Balet",
    "Bandyngul",
    "Baschet",
    "Baseball",
    "Biatlon",
    "Biliard",
    "Bob",
    "Box",
    "Bowling",
    "Bridge",
    "Caiac-canoe",
    "Canotaj",
    "Carting",
    "Călărie",
    "Ciclism",
    "Cricket",
    "Culturism",
    "Curling",
    "Dans",
    "Darts",
    "Fotbal",
    "Handball",
    "Judo",
    "Karting",
    "Karate",
    "Kick-Box",
    "Kitesurfing",
    "Lupte",
    "Motociclism",
    "Natație",
    "Oină",
    "Olimpism",
    "Orientare",
    "Planorism",
    "Parapantism",
    "Parașutism",
    "Patinaj artistic",
    "Patinaj viteză",
    "Paintball",
    "Pentatlon",
    "Pescuit",
    "Petanque",
    "Polo",
    "Popice",
    "Raliu",
    "Role",
    "Rugby",
    "Rugby subacvatic",
    "Sanie",
    "Sărituri cu schiurile",
    "Scrimă",
    "Scufundare",
    "Scufundare liberă (în ape)",
    "Skeleton",
    "Snowboard",
    "Snooker",
    "Speologie",
    "Streetball",
    "Sport electronic",
    "Sumo",
    "Șah",
    "Schi",
    "Surf",
    "Tae Bo",
    "Taekwondo",
    "Tenis de câmp",
    "Tenis de masă",
    "Tir sportiv",
    "Tir cu arcul",
    "Tir dinamic IPSC",
    "Tir subacvatic",
    "Triatlon",
    "Volei"
  ],
  roles: [],
}