<template>
  <div>Canceled</div>
</template>

<script>
export default {
  name: "OrderCanceled",
  beforeCreate() {
    this.$router.push("/pricing");
  },
};
</script>