<template>

  <v-text-field
    color="blue"
    v-on:input="$emit('input', $event)"
    required
    v-bind="$attrs"
    outlined
    dense
></v-text-field>

</template>

<script>
export default {
  inheritsAttrs: false,
  name: "Input",
  prop: ["value"],
  data() {
    return {
      content: this.value,
    };
  },
};
</script>
