<template>
  <v-container fluid class="body">
    <div class="spinner">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
        color="primary"
      ></v-progress-circular>
      <br />Activam contul...
    </div>
  </v-container>
</template>

<script>
import { HTTP } from "../../utils/http-common";
export default {
  name: "confirm",
  created() {
    let query = "confirm?token=" + this.$route.query.token;
    HTTP.post(query, {
      responseType: "json",
    }).then((resp) => {
      if (resp["data"]["status"]["code"] == 200) {
        alert("Contul dumneavoastra a fost inregistrat cu succes");
        this.$router.push("/");
      } else if (resp["data"]["status"]["code"] != 200) {
        alert(resp["data"]["status"]["message"]);
      } else {
        alert("A aparut o eroare interna!");
      }
    });
  },
};
</script>

<style scoped>
.spinner {
  margin: 0 auto;
  margin-top: 100px;
  text-align: center;
}
.body {
  background-color: rgb(145, 198, 216);
  height: 100%;
}
</style>
