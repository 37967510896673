<template>
  <v-app>
    <router-view class="app" />
    <navbar v-if="user != null" />
    <sidebar v-if="user != null" />
  </v-app>
</template>

<script>
import sidebar from "./components/sidebar/sidebar.vue";
import navbar from "./components/navbar/navbar.vue";
import clientStorage from "./store/clientStorage.js";
import { HTTP } from "../src/utils/http-common";
import constants from "./utils/constants";
export default {
  name: "App",
  components: {
    sidebar,
    navbar,
  },
  data: () => ({
    user: clientStorage.getUser(),
  }),

  beforeCreate() {
    HTTP.get("user", {
      responseType: "json",
    }).then((resp) => {
      if (resp["data"]["status"]["code"] == 200) {
        let user = resp["data"]["data"];
        clientStorage.setUser(user, false);
      } else if (resp["data"]["status"]["code"] != 200) {
        alert(resp["data"]["status"]["message"]);
        this.$router.push("/");
      } else {
        alert(resp["data"]["status"]["message"]);
        this.$router.push("/");
      }
    });
    HTTP.get("roles", {
      responseType: "json",
    }).then((resp) => {
      if (resp["data"]["status"]["code"] == 200) {
        Object.assign(constants.roles, resp["data"]["data"]);
      } else if (resp["data"]["status"]["code"] != 200) {
        alert(resp["data"]["status"]["message"]);
        this.$router.push("/");
      } else {
        alert(resp["data"]["status"]["message"]);
        this.$router.push("/");
      }
    });
  },
};
</script>

<style scoped></style>
