<template>
  <div>Suceess</div>
</template>

<script>
export default {
  name: "OrderSuccess",
  beforeCreate() {
    alert("Contul tau a fost alimentat cu success");
    this.$router.push("/pricing");
  },
};
</script>