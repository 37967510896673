<template>
  <v-container fluid class="body">
    <v-row justify="center">
      <logo />
    </v-row>
    <div class="form">
      <b>Reseteaza parola</b>
      <v-text-field
        v-model="pass"
        label="Introdu parola"
        type="password"
      ></v-text-field>
      <v-text-field
        v-model="pass2"
        type="password"
        label="Confirma parola"
      ></v-text-field>
      <v-btn rounded color="primary" dark @click="recover"> Trimite </v-btn>
    </div>
  </v-container>
</template>

<script>
import logo from "../../components/logo.vue";
import { HTTP } from "../../utils/http-common";
export default {
  name: "resetPass",
  components: { logo },
  data: () => ({
    pass: null,
    pass2: null,
  }),
  methods: {
    recover() {
      let formdata = new FormData();
      formdata.append("password", this.pass);
      formdata.append("password2", this.pass2);
      formdata.append("token", this.$route.query.token);
      console.log(this.pass);
      console.log(this.pass2);
      HTTP.post("reset_pass", formdata, {
        responseType: "json",
      }).then((resp) => {
        if (resp["data"]["status"]["code"] == 200) {
          alert("Parola resetata cu success");
          this.$router.push("/");
        } else if (resp["data"]["status"]["code"] != 200) {
          alert(resp["data"]["status"]["message"]);
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
  },
};
</script>

<style scoped>
.form {
  background-color: white;
  display: inline-block;
  display: table;
  margin: 0 auto;
  margin-top: 50px;
  padding: 25px;
  text-align: center;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 400px;
}
v-img {
  margin-top: 50px;
  margin: 0 auto;
}
.divider {
  margin-top: 10px;
  margin-bottom: 30px;
}
.body {
  background-color: rgb(145, 198, 216);
  height: 100%;
}
</style>