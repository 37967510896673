

const clientStorage = {
    setUser(user, with_tokens = true) {
        if (with_tokens == true) {
            var auth_token = user['auth_token']
            var refresh_token = user['refresh_token']
            delete user['auth_token']
            delete user['refresh_token']
            localStorage.setItem('auth_token', auth_token)
            localStorage.setItem('refresh_token', refresh_token)
        }
        localStorage.setItem('user', JSON.stringify(user))
        if (with_tokens == true)
            window.location.href = "/mgm15"

    },
    setMGMLogo(path) {
        let user = this.getUser();
        user['mgm_logo'] = path
        this.deleteUser(false)
        this.setUser(user, false)
    },
    deleteUser(with_disconnect = true) {
        localStorage.removeItem('user');
        if (with_disconnect == true) {
            localStorage.removeItem('auth_token');
            localStorage.removeItem('refresh_token');
            window.location.href = "/"
        }
    },
    getUser() {
        var user = JSON.parse(localStorage.getItem('user'))
        return user;
    },
    getRefreshToken() {
        return localStorage.getItem('refresh_token')
    },
    getToken() {
        return localStorage.getItem('auth_token')
    },

    getAppLanguage() {
        return localStorage.getItem("appLanguage");
    },

    getAppSlot() {
        return parseInt(localStorage.getItem("appSlot"));
    },

    setAppLanguage(language, slot) {
        localStorage.setItem("appLanguage", language); // Whenever we change the appLanguage we save it to the localStorage
        localStorage.setItem("appSlot", slot);
    }
}

export default clientStorage