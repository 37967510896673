<template>
  <v-container fluid class="body">
    <v-card 
    rounded
    raised
    class="form">
      <logo />
      <p style="color: #202124; font-size: 20px; font-weight: 400; margin-bottom: 20px;">Recuperarea contului</p>
      <v-text-field
        v-model="email"
        label="Introduceţi adresa de e-mail"
      ></v-text-field>

      <div class ="bottom-row">
        <v-btn color="primary" 
        depressed 
        outlined 
        dark 
        router-link :to="'/'">Înapoi</v-btn>

        <v-btn color="primary" dark @click="recover">Înainte</v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import logo from "../../components/logo.vue";
import { HTTP } from "../../utils/http-common";
export default {
  name: "recoverPass",
  components: { logo },
  data: () => ({
    email: null,
  }),
  methods: {
    recover() {
      let formdata = new FormData();
      formdata.append("email", this.email);
      HTTP.post("recover_pass", formdata, {
        responseType: "json",
      }).then((resp) => {
        if (resp["data"]["status"]["code"] == 200) {
          alert(
            "Un email a fost trimis la adresa introdusa. Accesati linkul din email pentru a reseta parola"
          );
          this.$router.push("/");
        } else if (resp["data"]["status"]["code"] != 200) {
          alert(resp["data"]["status"]["message"]);
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
  },
};
</script>

<style scoped>
.form {
  padding: 48px 40px;
  text-align: center;
  width: 448px;
}

.body {
  background-color: rgb(145, 198, 216);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-row{
  display: flex;
   justify-content: space-between;
}
</style>