import Vue from 'vue'
import App from './App.vue'
import VueRouter from "vue-router";
import router from "./router";
import vuetify from './plugins/vuetify'
import {i18n} from './plugins/i18n'
import CountryFlag  from 'vue-country-flag'


Vue.config.productionTip = false

Vue.use(VueRouter);
Vue.component('country-flag', CountryFlag)


new Vue({
  i18n,
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

