<template>
  <v-row justify="center">
    <v-col md="6" class="text-center">
      <v-row align="center" justify="center">
        <v-checkbox
          v-on:change="$emit('input', $event)"
          v-bind="$attrs"
          color="primary"
          value="primary"
          hide-details
        ></v-checkbox>
        <v-dialog v-model="dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              id="no-background-hover"
              color="white"
              elevation="0"
              v-bind="attrs"
              v-on="on"
            >
              Vezi aici
            </v-btn>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2"> GDPR </v-card-title>

            <v-card-text>
              În virtutea REGULAMENTULUI (UE) 2016/679 privind protecția
              persoanelor fizice în ceea ce privește prelucrarea datelor cu
              caracter personal şi privind libera circulație a acestor date și
              de abrogare a Directivei 95/46/CE (Regulamentul general privind
              protecția datelor), <b>Neuromotrica</b> îşi asumă obligaţia de a
              administra în condiţii de siguranţă datele personale furnizate de
              <b>Client</b> despre el însuşi și înregistrările realizate în urma
              testelor. Rolul prezentului acord este de a vă explica modul în
              care datele personale ale Clientului sunt prelucrate, scopul în
              care sunt utilizate şi drepturile pe care le are Clientul ale
              cărui date personale sunt prelucrate. <br /><br /><b
                >Scopul prelucrării datelor</b
              >
              cu caracter personal constă în principal în acordarea de servicii
              de evaluare a sportivilor și de cercetare în domeniul sportului.
              Neuromotrica prelucrează următoarele date cu caracter personal:
              <br /><br />⦁ Nume și prenume, în vederea personalizării
              raportului de evaluare, date ce vor fi stocate ulterior procesării
              în mod criptat; <br /><br />⦁ Gen, în vederea personalizării
              raportului de evaluare și oferirea de interpretări adaptate
              genului sportivului; <br /><br />⦁ Data nașterii, în vederea
              personalizării raportului de evaluare și oferirea de interpretări
              adaptate vârstei sportivului; <br /><br />⦁ Greutatea și
              înălțimea, în vederea personalizării raportului de evaluare și
              oferirea de interpretări adaptate greutății și înălțimii
              sportivului; <br /><br />⦁ Sport, în vederea personalizării
              raportului de evaluare și oferirea de interpretări adaptate
              specificului sportului practicat; <br /><br /><b
                >Utilizarea în scopuri științifice, inclusiv cu accesul unor
                terți, a rezultatelor înregistrărilor din teste va fi
                <u>anonimizată</u> astfel încât persoanele să nu poată fi
                identificate.</b
              ><br /><br />
              Refuzul Dvs. în scopul prelucrării datelor cu caracter personal
              poate determina imposibilitatea acordării serviciilor de care
              doriţi să beneficiaţi. <br /><br />Prin bifarea prezentului Acord,
              Clientul declară că a fost informat cu privire la procesarea
              datelor cu caracter personal de către Neuromotrica, că a înţeles
              conţinutul său şi că şi-a exprimat liber consimţământul în scopul
              prelucrării datelor personale. <br /><br />În situaţia în care
              ulterior oferirii acordului aveţi nelămuriri sau suspiciuni
              privind o posibilă încălcare a confidențialităţii ori doriţi să
              reveniţi asupra acestuia, vă rugăm să ne transmiteți un e-mail la
              contact@neuromotrica.ro, pentru a ne conforma solicitărilor Dvs.
              ori a înlătura orice neînţelegeri în cel mai scurt timp şi a vă
              asigura de deplinul respect pe care îl acordăm datelor Dvs.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog = false">
                Am inteles
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  inheritsAttrs: false,
  name: "MGMGDPR",
  data: () => ({
    dialog: false,
  }),
};
</script>

<style scoped>
#no-background-hover::before {
  background-color: transparent !important;
}
#no-background-hover {
  margin-top: 20px;
}
</style>
