<template>
  <v-img
    max-height="250"
    max-width="350"
    src="../assets/neuromotrica.png"
  ></v-img>
</template>

<script>
export default {
  name: "logo",
};
</script>
