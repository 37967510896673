<template>
<div>
  <v-main style="margin-top: 20px;">
  <v-container>
    <v-form ref="form" lazy-validation v-model="valid">
      <Dropdown
        v-model="gender"
        label="Gen"
        :items="genders"
      />
      <Input
        v-model="year"
        label="An"
      />
      <Dropdown
        v-model="sport"
        label="Sportul"
        :items="sports"
      />
      <v-row justify="center" style="margin-top: 10px;"> 
      <LoaderButton
        :loading="loading2"
        :disabled="loading2"
        @click.native="getRaport"
        label="Generare raport"
        loadingLabel="Prelucrare..."
      />
      </v-row>
    </v-form>
  </v-container>
  </v-main>
</div>
</template>

<script>
import Dropdown from "../../../components/inputs/Dropdown.vue";
import Input from "../../../components/inputs/Input.vue";
import constants from "../../../utils/constants";
import LoaderButton from "../../../components/inputs/LoaderButton.vue";
import { HTTP } from "../../../utils/http-common";
export default {
  name: "MGMGlobalCentralizedStatistics",
  components: {
    Dropdown,
    Input,
    LoaderButton,
  },
  data: () => ({
    valid: true,
    gender: null,
    genders: constants.genders,
    year: null,
    sport: null,
    sports: constants.MGMSports,
    selectedMGMVars: [],
    loading2: false,
  }),
  methods: {
    getRaport() {
      this.loading2 = true;
      let formData = new FormData();
      if(this.gender)
        formData.append("gender", this.gender);
      if(this.year)
        formData.append("year", parseInt(this.year));
      if(this.sport)
        formData.append("sport", this.sport);

      HTTP.post("MGM/global_statistics", formData, {
        responseType: "blob",
      }).then((resp) => {
        this.loading2 = false;
        if (resp["status"] == 200) {
          const blob = new Blob([resp["data"]]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob); //create the download url
          link.download = `rezultat.csv`; //the name of the downloaded file
          // document.body.appendChild(downloadElement);
          link.click(); //click to file
          // document.body.removeChild(downloadElement); //remove the element
          URL.revokeObjectURL(link.href); //release the object  of the blob
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
  },
};
</script>
