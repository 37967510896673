<template>
  <v-container fluid class="body">
    <v-card 
    rounded 
    class="form"
    raised>
      <logo />
      <p style="color: #202124; font-size: 20px; font-weight: 400; margin-bottom: 20px;">Conectaţi-vă</p>
      <v-text-field v-model="email" label="Introduceţi adresa de e-mail"></v-text-field>
      <v-text-field
        v-model="password"
        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
        :rules="[rules.required, rules.min]"
        :type="show1 ? 'text' : 'password'"
        name="input-10-1"
        label="Introduceţi parola"
        @click:append="show1 = !show1"
        @keyup.enter="login"
      ></v-text-field>
      <div style="text-align: left; margin-bottom: 20px;"><a href="/recover_pass">Aţi uitat parola?</a></div>

      <div class ="bottom-row">
        <v-btn color="primary" dark @click="goToRegister">Creaţi un cont</v-btn>

        <v-btn color="primary" dark @click="login">Conectare</v-btn>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import logo from "../../components/logo.vue";
import { HTTP } from "../../utils/http-common";
import clientStorage from "../../store/clientStorage.js"
export default {
  components: { logo },
  name: "login",
  data: () => ({
    password: null,
    email: null,
    show1: false,
    rules: {
      required: (value) => !!value || "Required.",
    },
  }),
  methods: {
    goToRegister() {
      this.$router.push("/register");
    },
    login() {
      let formData = new FormData();
      formData.append("email", this.email);
      formData.append("password", this.password);
      HTTP.post("login", formData, {
        responseType: "json",
      }).then((resp) => {
        if (resp["data"]["status"]["code"] == 200) {
          let user = resp["data"]["data"];
          clientStorage.setUser(user);
        } else if (resp["data"]["status"]["code"] != 200) {
          alert(resp["data"]["status"]["message"]);
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
  },
};
</script>

<style scoped>
.form {
  padding: 48px 40px; 
  text-align: center;
  width: 448px;
  height: 500px;
}

.body {
  background-color: rgb(145, 198, 216);
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
a {
  text-decoration: none;
}

.bottom-row{
  display: flex;
   justify-content: space-between;
}
</style>
