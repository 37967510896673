<template>
<div>
      <v-select
        v-model="selectedSport"
        v-on:input="$emit('input', $event)"
        v-bind="$attrs"
        dense
        outlined
      ></v-select>

      <v-text-field
        v-model="otherSport"
        v-if="selectedSport == 'Altul'"
        :rules="[(v) => !!v || 'Completati acest camp!']"
        v-on:input="$emit('input', $event)"
        color="blue"
        label="Sport *"
        required
        outlined
        dense
      ></v-text-field>

</div>
</template>

<script>
export default {
  inheritsAttrs: false,
  name: "MGMselectWithOthers",
  data: () => ({
    selectedSport: null,
    otherSport: null,
  }),
};
</script>
