<template>
  <v-navigation-drawer
    v-model="drawer"
    :mini-variant.sync="mini"
    app
    clipped
    permanent
  >
    <v-layout justify-space-between column fill-height>
      <v-list dense style="padding: 0 !important">
        <v-list-item class="px-2" style="height: 62px !important">
          <v-list-item-avatar>
            <v-img
              src="https://cdn4.iconfinder.com/data/icons/flat-pro-business-set-1/32/people-customer-unknown-512.png"
            >
            </v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ user.email }}</v-list-item-title>
            <v-list-item-subtitle>ID: {{ user.Id }}</v-list-item-subtitle>
          </v-list-item-content>

          <v-btn icon @click.stop="mini = !mini">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item>

        <v-divider></v-divider>

        <!-- MGM BUTON -->
        <v-list-item link router-link :to="'/mgm15'" active-class="activelink">
          <v-list-item-icon>
            <v-icon>mdi-speedometer-slow</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Mgm15</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <!-- ZONA ADMIN -->
        <v-list-item
          link
          router-link
          :to="'/admin'"
          v-if="user.role.Name == 'Admin'"
          active-class="activelink"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-multiple</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Administrativ</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-group prepend-icon="mdi-chart-bar" no-action :value="false">
          <template v-slot:activator>
            <v-list-item-title>Statistici</v-list-item-title>
          </template>

          <!-- BUTON CENTRALIZATOR-->
          <v-list-item
            link
            router-link
            :to="'/mgm_global_centralized_statistics'"
            v-if="user.role.Name == 'Admin' || user.role.Name == 'Angajat'"
          >
            <v-list-item-title>Statistici globale sportivi</v-list-item-title>
          </v-list-item>

          <v-list-item link router-link :to="'/mgm_statistics'">
            <v-list-item-title>Statistici sportivi</v-list-item-title>
          </v-list-item>
        </v-list-group>

        <v-list-item link router-link :to="'/pricing'">
          <v-list-item-icon>
            <v-icon>mdi-cash-check</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Abonamente</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list dense style="padding: 0 !important">
        <v-list-item
          link
          router-link
          :to="'/user_profile'"
          active-class="activelink"
        >
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Setări cont</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item link active-class="activelink" @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Deconectare</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-layout>
  </v-navigation-drawer>
</template>

<script>
import clientStorage from "../../store/clientStorage";
import { HTTP } from "../../utils/http-common";
export default {
  name: "sidebar",
  components: {},

  mounted() {
    this.$root.$on("DeschideHamburger", () => {
      this.mini = !this.mini;
    });
  },
  data() {
    return {
      mini: false,
      selectedItem: 0,
      drawer: true,
      user: clientStorage.getUser(),
    };
  },
  methods: {
    logout() {
      let formData = new FormData();
      formData.append("refresh_token", clientStorage.getRefreshToken());
      HTTP.post("logout", formData, {
        responseType: "json",
      }).then((resp) => {
        if (resp["data"]["status"]["code"] == 200) {
          clientStorage.deleteUser();
        } else if (resp["data"]["status"]["code"] != 200) {
          alert(resp["data"]["status"]["message"]);
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
  },

  created() {},
};
</script>

<style>
.v-list-item--active {
  color: #1976d2 !important;
}
</style>
