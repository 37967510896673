<template>
  <div>
    <v-main style="margin-top: 20px">
      <v-container>
        <v-row>
          <v-col cols="12" lg="12" md="12" sm="12">
            <v-card class="mx-auto" outlined elevation="2">
              <div class="d-flex justify-center mb-4 mt-4">
                <v-img
                  max-height="50"
                  max-width="50"
                  src="https://cdn4.iconfinder.com/data/icons/flat-pro-business-set-1/32/people-customer-unknown-512.png"
                >
                </v-img>
              </div>
              <div class="text-h6 text-center mb-4">
                {{ user.email }}
              </div>
              <div class="d-flex justify-center flex-wrap mb-7">
                <div
                  class="d-flex justify-center text-center mx-5 text-secondary"
                >
                  <v-icon class="mr-2">mdi-information-outline</v-icon>
                  ID: {{ user.Id }}
                </div>
                <div
                  class="d-flex justify-center text-center mx-5 text-secondary"
                >
                  <v-icon class="mr-2">mdi-account-tie</v-icon>
                  {{ user.role.Name }}
                </div>
              </div>

              <div class="d-flex justify-center mb-10">
                <v-card outlined class="mx-5 pa-4 text-center">
                  <div class="text-caption font-weight-medium">
                    Utilizări MGM
                  </div>
                  <div class="text-caption font-weight-medium">
                    {{ user.MGM_usages }}
                  </div>
                </v-card>

                <v-card outlined class="mx-5 pa-4 text-center">
                  <div class="text-caption font-weight-medium">
                    Utilizări Centralizator
                  </div>
                  <div class="text-caption font-weight-medium">
                    {{ user.centralized_usages }}
                  </div>
                </v-card>

                <v-card outlined class="mx-5 pa-4 text-center">
                  <div class="text-caption font-weight-medium">
                    Utilizări Comparativ
                  </div>
                  <div class="text-caption font-weight-medium">
                    {{ user.comparative_usages }}
                  </div>
                </v-card>
              </div>
              <template>
                <v-tabs v-model="tabs" grow>
                  <v-tab>Detalii profil</v-tab>
                  <v-tab>Logo raport</v-tab>
                  <v-tab>Limbă</v-tab>
                </v-tabs>
              </template>

              <v-tabs-items v-model="tabs">
                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" md="12">
                          <h1 class="my-4">Informaţii cont</h1>
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.name"
                            :label="$t('numeclient')"
                            :rules="[(v) => !!v || $t('numeobligatoriu')]"
                            style="padding-bottom: 0 !important"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.email"
                            :label="$t('emailuser')"
                            :rules="[(v) => !!v || $t('campobligatoriu')]"
                            style="padding-bottom: 0 !important"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.SSN"
                            :label="$t('cnp')"
                            :rules="[
                              (v) =>
                                Number.isInteger(Number(v)) ||
                                v == null ||
                                $t('numarintreg'),
                            ]"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.ID_serial"
                            :label="$t('serie')"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.ID_number"
                            :label="$t('nrci')"
                            :rules="[
                              (v) =>
                                Number.isInteger(Number(v)) ||
                                v == null ||
                                $t('numarintreg'),
                            ]"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.city"
                            :label="$t('oras')"
                            style="padding-bottom: 0 !important"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.county"
                            :label="$t('judet')"
                            style="padding-bottom: 0 !important"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="4"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.address"
                            :label="$t('adresa')"
                            style="padding-bottom: 0 !important"
                          />
                        </v-col>
                      </v-row>

                      <v-divider class="my-2" />

                      <v-row>
                        <v-col cols="12" md="12">
                          <h1 class="mt-2 mb-4">Persoane juridice</h1>
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.company_name"
                            :label="$t('numecompanie')"
                            style="padding-bottom: 0 !important"
                          />
                        </v-col>
                        <v-col
                          cols="12"
                          md="6"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.CUI"
                            :label="$t('cui')"
                            style="padding-bottom: 0 !important"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.nr_reg_com"
                            :label="$t('nrregcom')"
                            style="padding-bottom: 0 !important"
                          />
                        </v-col>

                        <v-col
                          cols="12"
                          md="6"
                          style="padding-bottom: 0; padding-top: 6px"
                        >
                          <Input
                            v-model="backupuser.social_office"
                            :label="$t('sediusocial')"
                            style="padding-bottom: 0 !important"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer />
                      <v-btn
                        text
                        v-bind="attrs"
                        @click="onResetInputChanges()"
                        style="
                          text-transform: unset !important;
                          margin-right: 10px;
                        "
                      >
                        Resetează
                      </v-btn>

                      <v-btn
                        color="success"
                        v-bind="attrs"
                        @click="onSaveInputChanges()"
                        style="text-transform: unset !important"
                      >
                        Salvează
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-col cols="12" lg="12" md="12" sm="12">
                        <v-card
                          class="mx-auto"
                          style="padding-top: 20px !important"
                          outlined
                          elevation="2"
                        >
                          <div class="div1">
                            <v-img
                              height="60"
                              width="150"
                              :src="this.user_logo"
                              contain
                            ></v-img>
                          </div>
                          <v-card-title>ÎNCARCĂ LOGO</v-card-title>

                          <v-card-subtitle>
                            Ai opțiunea de a personaliza rapoartele de evaluare
                            cu logo-ul tău. Acesta se va afișa în antetul
                            raportului.
                          </v-card-subtitle>

                          <!-- 
                        <v-card-text>
                        <v-radio-group
                          v-model="radios"
                          v-on:change="OnRadioChange"
                          style="margin-top: 0"
                        >
                          <template v-slot:label>
                            <div>
                              Alege <strong>poziţia</strong> în care va fi afişată emblema
                              în raportul MGM15
                            </div>
                          </template>
                          <v-radio value="1">
                            <template v-slot:label>
                              <div>
                                În partea din
                                <strong class="primary--text">stânga</strong> a antetului
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="2">
                            <template v-slot:label>
                              <div>
                                În partea din
                                <strong class="primary--text">mijloc</strong> a antetului
                              </div>
                            </template>
                          </v-radio>
                          <v-radio value="3">
                            <template v-slot:label>
                              <div>
                                În partea din
                                <strong class="primary--text">dreapta</strong> a antetului
                              </div>
                            </template>
                          </v-radio> 
                        </v-radio-group>
                      </v-card-text> 
                      -->

                          <v-card-actions>
                            <v-btn text @click="show = !show">
                              Instrucţiuni
                              <v-icon>{{
                                show ? "mdi-chevron-up" : "mdi-chevron-down"
                              }}</v-icon>
                            </v-btn>

                            <v-spacer></v-spacer>

                            <v-btn
                              icon
                              color="red"
                              v-if="deleteLogoBut == true"
                              @click="removeLogo"
                            >
                              <v-icon large> mdi-delete-forever </v-icon>
                            </v-btn>

                            <v-btn icon @click="onAddFileChoose">
                              <v-icon large> mdi-plus </v-icon>
                            </v-btn>
                          </v-card-actions>

                          <v-expand-transition>
                            <div v-show="show">
                              <v-divider></v-divider>

                              <v-card-text>
                                Pentru a încărca logo-ul pe raportul de evaluare
                                te rugăm să încarci o poză în format .PNG, cu
                                dimensiuni maxime de 60 pixeli x 150 pixeli
                                <v-img
                                  style="margin-top: 10px"
                                  :src="require('@/assets/i3.png')"
                                ></v-img>
                              </v-card-text>
                            </div>
                          </v-expand-transition>
                        </v-card>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-tab-item>

                <v-tab-item>
                  <v-card flat>
                    <v-card-text>
                      <v-col cols="12" lg="12" md="12" sm="12">
                        <v-card class="mx-auto" outlined elevation="2">
                          <v-list flat>
                            <v-subheader>{{
                              $t("chooselanguage")
                            }}</v-subheader>
                            <v-list-item-group
                              v-model="selectedItem"
                              color="primary"
                            >
                              <v-list-item
                                v-for="(item, i) in items"
                                :key="i"
                                @click="setLocale(item.language, item.slot)"
                                :disabled="item.disabled"
                              >
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="item.text"
                                  ></v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-icon>
                                  <country-flag
                                    :rounded="true"
                                    :country="item.flag"
                                  />
                                </v-list-item-icon>
                              </v-list-item>
                            </v-list-item-group>
                          </v-list>
                        </v-card>
                      </v-col>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </v-card>
          </v-col>

          <v-snackbar v-model="snackbar" timeout="-1" color="primary">
            <strong>Atenţie - Ai modificări nesalvate!</strong>

            <template v-slot:action="{ attrs }">
              <v-btn
                text
                v-bind="attrs"
                @click="onResetChanges()"
                style="text-transform: unset !important; margin-right: 10px"
              >
                Resetează
              </v-btn>

              <v-btn
                color="success"
                v-bind="attrs"
                @click="onSaveChanges()"
                style="text-transform: unset !important"
              >
                Salvează
              </v-btn>
            </template>
          </v-snackbar>

          <div class="mgm_logo">
            <v-file-input
              id="fileUpload"
              v-model="logo"
              :rules="rules"
              accept="image/png"
              placeholder="Selectaţi un logo"
              prepend-icon="mdi-camera"
              label="MGM Logo"
              class="d-none"
              @change="onSelectedPhoto"
            ></v-file-input>
            <LoaderButton
              label="Urcă Logo"
              loadingLabel="Procesare..."
              @click.native="upload"
              class="d-none"
            />
          </div>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoaderButton from "../../components/inputs/LoaderButton.vue";
import { HTTP } from "../../utils/http-common";
import "vue-advanced-cropper/dist/style.css";
import clientStorage from "../../store/clientStorage";
import Input from "../../components/inputs/Input.vue";
export default {
  components: { LoaderButton, Input },
  name: "userProfile",
  data: () => ({
    rules: [(value) => !value],
    logo: null,
    snackbar: false,
    radios: null,
    show: false,
    user_logo: null,
    previousRadio: null,
    previousLogo: null,
    deleteLogoBut: false,
    user: clientStorage.getUser(),
    backupuser: {},
    tabs: null,
    selectedItem: clientStorage.getAppSlot() || 0,
    items: [
      { text: "Română", flag: "ro", language: "ro", slot: 0, disabled: 0 },
      { text: "English", flag: "usa", language: "en", slot: 1, disabled: 1 },
      { text: "Español", flag: "es", language: "es", slot: 2, disabled: 1 },
      { text: "Deutsch", flag: "de", language: "de", slot: 3, disabled: 1 },
      { text: "Italiano", flag: "it", language: "it", slot: 4, disabled: 1 },
    ],
  }),
  beforeMount() {
    this.backupuser = this.deepClone(this.user);
    this.radios = clientStorage.getUser()["mgm_logo_position"].toString();
    let user_mgm_logo = clientStorage.getUser().mgm_logo;
    if (user_mgm_logo != "") {
      this.deleteLogoBut = true;
      let URL = "MGM/logo?path=" + clientStorage.getUser().mgm_logo;
      HTTP.get(URL, { responseType: "blob" }).then((resp) => {
        if (resp["status"] == 200) {
          this.user_logo = global.URL.createObjectURL(resp["data"]);
          this.previousLogo = this.user_logo;
        } else {
          alert("A aparut o eroare interna");
          this.$router.push("/mgm15");
        }
      });
    } else {
      this.loadDefaultLogo();
    }
    this.previousRadio = this.radios;
  },
  methods: {
    deepClone(data) {
      var type = typeof data;
      var obj;
      if (type === "array") {
        obj = [];
      } else if (type === "object") {
        obj = {};
      } else {
        //No more next level
        return data;
      }
      if (type === "array") {
        for (var i = 0, len = data.length; i < len; i++) {
          obj.push(this.deepClone(data[i]));
        }
      } else if (type === "object") {
        for (var key in data) {
          obj[key] = this.deepClone(data[key]);
        }
      }
      return obj;
    },
    setLocale(locale, slot) {
      this.$i18n.locale = locale;
      clientStorage.setAppLanguage(locale, slot);
    },
    upload() {
      let formData = new FormData();
      formData.append("logo", this.logo);
      formData.append("position", parseInt(this.radios));
      HTTP.post("MGM/logo", formData).then((resp) => {
        if (resp["data"]["status"]["code"] == 200) {
          alert("Logo a fost urcat cu success");
          clientStorage.setMGMLogo(resp["data"]["data"]["path"]);
          this.deleteLogoBut = true;
        } else if (resp["data"]["status"]["code"] != 200) {
          alert(resp["data"]["status"]["message"]);
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
    removeLogo() {
      HTTP.delete("MGM/logo").then((resp) => {
        if (resp["data"]["status"]["code"] == 200) {
          alert("Logo a fost sters cu success");
          this.loadDefaultLogo();
          this.previousLogo = null;
          this.deleteLogoBut = false;
          this.onResetChanges();
        } else if (resp["data"]["status"]["code"] != 200) {
          alert(resp["data"]["status"]["message"]);
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
    loadDefaultLogo() {
      this.user_logo = require("../../assets/nologo.png");
    },
    onAddFileChoose() {
      document.getElementById("fileUpload").click();
    },
    OnRadioChange() {
      this.snackbar = true;
    },
    OnInputChange() {
      this.snackbar = true;
    },
    onSaveChanges() {
      this.snackbar = false;
      this.upload();
    },
    onSelectedPhoto() {
      let formData = new FormData();
      formData.append("logo", this.logo);
      HTTP.post("MGM/check_logo", formData).then((resp) => {
        if (resp["data"]["status"]["code"] == 200) {
          this.user_logo = URL.createObjectURL(this.logo);
          this.snackbar = true;
        } else if (resp["data"]["status"]["code"] != 200) {
          alert(resp["data"]["status"]["message"]);
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
    onResetChanges() {
      this.snackbar = false;
      this.backupuser = this.deepClone(this.user);
      this.radios = this.previousRadio;
      if (this.previousLogo != null) this.user_logo = this.previousLogo;
      else this.loadDefaultLogo();
    },
    onResetInputChanges() {
      this.backupuser = this.deepClone(this.user);
    },
    onSaveInputChanges() {
      //salveaza ce a bagat in backupuser
      this.user = this.deepClone(this.backupuser);
      let formData = new FormData();
      var keys = [
        "name",
        "SSN",
        "ID_serial",
        "ID_number",
        "city",
        "county",
        "address",
        "company_name",
        "CUI",
        "nr_reg_com",
        "social_office",
      ];
      var values = [
        this.user.name,
        this.user.SSN,
        this.user.ID_serial,
        this.user.ID_number,
        this.user.city,
        this.user.county,
        this.user.address,
        this.user.company_name,
        this.user.CUI,
        this.user.nr_reg_com,
        this.user.social_office,
      ];
      formData.append("keys", keys);
      formData.append("values", values);
      HTTP.put("user", formData).then((resp) => {
        if (resp["data"]["status"]["code"] == 200) {
          alert("Datele au fost actualizate cu succes.");
          clientStorage.setUser(resp["data"]["data"], false);
        } else if (resp["data"]["status"]["code"] != 200) {
          alert(resp["data"]["status"]["message"]);
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
  },
};
</script>

<style scoped>
.mgm_logo {
  margin: 16px;
  text-align: center;
}
.mgmLogoInput {
  width: 50%;
  margin: 0 auto;
}
.img-logo {
  margin: 0 auto;
}
.div1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: auto;
}
</style>