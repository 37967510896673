<template>
  <v-row justify="center">
    <v-col md="6">
      <v-radio-group v-on:change="$emit('input', $event)" mandatory row>
        <v-radio label="Optojump 2 bare" value="0"></v-radio>
        <v-radio label="Optojump 8 bare (patrat)" value="1"></v-radio>
      </v-radio-group>
    </v-col>
  </v-row>
</template>

<script>
export default {
  inheritsAttrs: false,
  name: "MGMoptojumpRadios",
};
</script>
