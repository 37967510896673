<template>

      <v-menu
        ref="birthday"
        v-model="birthday"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="date"
            readonly
            v-bind="$attrs"
            v-on:input="$emit('input', $event)"
            v-on="on"
            outlined
            dense
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          :active-picker.sync="activePicker"
          :max="
            new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
              .toISOString()
              .substr(0, 10)
          "
          min="1950-01-01"
          @change="save"
        ></v-date-picker>
      </v-menu>
</template>

<script>
export default {
  inheritsAttrs: false,
  name: "MGMdateInput",
  data: () => ({
    date: null,
    activePicker: null,
    birthday: false,
  }),
  watch: {
    birthday(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  methods: {
    save(date) {
      this.$refs.birthday.save(date);
      this.$emit("input", date);
    },
  },
};
</script>
