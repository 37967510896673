import axios from 'axios';
import settings from "../utils/settings"
import clientStorage from "../store/clientStorage"

export const HTTP = axios.create({
  baseURL: settings.BASE_URL[settings.ENV],
  headers: {
    'Authorization': 'Bearer ' + clientStorage.getToken()
  }
})

HTTP.interceptors.response.use(
  response => {
    console.log(response)
    if (response.status === 200 || response.status === 201) {
      if (response.data.status !== undefined && response.data.status.code == 403)
        clientStorage.deleteUser()
      else
        return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  }
);
