import Vue from 'vue'
import VueI18n from 'vue-i18n'
import clientStorage from '../store/clientStorage'

Vue.use(VueI18n)

  
export const i18n = new VueI18n({
    
    locale: clientStorage.getAppLanguage() || 'ro',
    fallbackLocale: 'ro',
    
    messages:{
        ro:{
            mgm1: 'Pentru a genera un raport de evaluare a sportivului, te rugăm să completezi următorul formular.',
            mgm2: 'Toate secțiunile marcate cu * sunt necesar a fi completate pentru personalizarea raportului.',
            chooselanguage: 'Alege o limbă',
            numesportiv: 'Nume sportiv *',
            numeobligatoriu: 'Numele este obligatoriu!',
            prenumesportiv: 'Prenume sportiv *',
            prenumeobligatoriu: 'Prenumele este obligatoriu!',
            greutatesportiv: 'Greutate sportiv (kg) *',
            greutateintreaga: 'Greutatea trebuie să fie un numar întreg!',
            greutateobligatorie: 'Greutatea este obligatorie!',
            inaltimesportiv: 'Înălţime sportiv *',
            inaltimeintreaga: 'Înălţimea trebuie să fie un numar!',
            inaltimeobligatorie: 'Înălţimea este obligatorie!',
            gensportiv: 'Gen *',
            genobligatoriu: 'Genul este obligatoriu!',
            nasteresportiv: 'Data naşterii *',
            nastereobligatorie: 'Data naşterii este obligatorie!',
            selecteazasportul: 'Selectează sportul',
            sportobligatoriu: 'Sportul este obligatoriu!',
            idsportiv: 'ID Sportiv',
            infomgm:'În pagina "Setări cont" ai opțiunea de a încărca logo-ul tău, iar acesta se va afișa în antetul raportului.',
            gdprobligatoriu: 'GDPR este obligatoriu!',
            gdprlabel: 'Îmi dau acordul pentru prelucrarea online a datelor personale',
            trimiteraportul: 'Trimite Raportul',
            generamraportul: 'Generăm Raportul..',
            infoid: 'Ca sportivul să poată vedea în istoric raportul, trebuie să introduci ID-ul lui. În situaţia în care nu are un cont înregistrat, poţi introduce ID-ul ulterior.',
            dataantrenamentului: 'Data antrenamentului',
            dataantrenamentuluiobligatorie: 'Data antrenamentului este obligatorie!',
            emailuser: 'E-mail utilizator',
            mgmusages: 'Utilizări MGM',
            numarintreg: 'Numărul trebuie să fie întreg',
            campobligatoriu: 'Acest câmp este obligatoriu!',
            centralizedusages: 'Utilizări Centralizator',
            comparativeusages: 'Utilizări Comparativ',
            selecteazarolul: 'Rol utilizator',
            editeazautilizatorul: 'Editează utilizatorul',
            numarpozitiv: 'Numărul trebuie să fie pozitiv!',
            numarpreamare: 'Numărul este prea mare!',
            numeclient: 'Nume',
            cnp:'CNP',
            numecompanie:'Numele companiei',
            cui:'CUI/CIF',
            serie: 'Serie buletin',
            nrci: 'Număr buletin',
            adresa: 'Adresă',
            oras: 'Oraş',
            judet: 'Judeţ',
            nrregcom: 'Nr. Reg. Com.',
            sediusocial:'Sediu social',
            verificacomanda:'Verifică comanda',
            infopricing:'Asigură-te că ţi-ai completat datele de facturare în pagina "Setări Cont"'



        },
        en:{
            mgm1: 'To generate an athlete evaluation report, please complete the following form.',
            mgm2: 'All sections marked with * need to be completed to customize the report.',
            chooselanguage: 'Choose a language',
            numesportiv: "Athlete's last name *",
            numeobligatoriu: 'Last name is required!',
            prenumesportiv: "Athlete's first name *",
            prenumeobligatoriu: 'First name is required!',
            greutatesportiv: "Athlete's weight (kg) *",
            greutateintreaga: 'Weight must be an integer!',
            greutateobligatorie: 'Weight is required',
        },
        
    }
  })