<template>
  <div>
    <v-main style="margin-top: 20px">
      <v-container>
        <v-form ref="form" lazy-validation v-model="valid">
          <v-row justify="center" style="margin-bottom: 10px"
            >{{ $t("mgm1") }}<br />
            {{ $t("mgm2") }}
          </v-row>
          <v-row justify="center" style="margin-bottom: 10px">
            <MGMinstructions
          /></v-row>
          <v-row>
            <v-col cols="12" md="6" style="padding-bottom: 0">
              <Input
                v-model="name"
                :label="$t('numesportiv')"
                :rules="[(v) => !!v || $t('numeobligatoriu')]"
                style="padding-bottom: 0 !important"
              />
            </v-col>
            <v-col cols="12" md="6" style="padding-bottom: 0">
              <Input
                v-model="surname"
                :label="$t('prenumesportiv')"
                :rules="[(v) => !!v || $t('prenumeobligatoriu')]"
                style="padding-bottom: 0 !important"
              />
            </v-col>

            <v-col cols="12" md="6" style="padding-bottom: 0">
              <Input
                v-model="weight"
                :label="$t('greutatesportiv')"
                :rules="[
                  (v) => Number.isInteger(Number(v)) || $t('numarintreg'),
                  (v) => !!v || $t('campobligatoriu'),
                ]"
              />
            </v-col>
            <v-col cols="12" md="6" style="padding-bottom: 0">
              <Input
                v-model="height"
                :label="$t('inaltimesportiv')"
                :rules="[
                  (v) => Number.isInteger(Number(v)) || $t('inaltimeintreaga'),
                  (v) => !!v || $t('inaltimeobligatorie'),
                ]"
              />
            </v-col>
            <v-col cols="12" md="6" style="padding-bottom: 0">
              <Dropdown
                v-model="gender"
                :label="$t('gensportiv')"
                :items="genders"
                :rules="[(v) => !!v || $t('genobligatoriu')]"
              />
            </v-col>
            <v-col cols="12" md="6" style="padding-bottom: 0">
              <MGMdateInput
                :label="$t('nasteresportiv')"
                :rules="[(v) => !!v || $t('nastereobligatorie')]"
                v-model="birthday"
              />
            </v-col>
            <v-col cols="12" md="6" style="padding-bottom: 0">
              <MGMselectWithOthers
                :label="$t('selecteazasportul')"
                :items="sports"
                :rules="[(v) => !!v || $t('sportobligatoriu')]"
                v-model="sport"
              />
            </v-col>
            <v-col cols="12" md="6" style="padding-bottom: 0">
              <Input :label="$t('idsportiv')" v-model="athlete" />
            </v-col>

            <v-alert
              dense
              text
              type="warning"
              style="margin: 10px 12px; width: 100%"
              dismissible
              border="left"
            >
              {{ $t("infoid") }}
            </v-alert>

            <v-alert
              dense
              text
              type="info"
              style="margin: 0 12px; width: 100%"
              dismissible
              border="left"
            >
              {{ $t("infomgm") }}
            </v-alert>
          </v-row>
          <MGMoptojumpRadios v-model="variant" />
          <MGMupload v-model="opto_xml" />
          <MGMGDPR
            v-model="GDPRcheckbox"
            :rules="[(v) => !!v || $t('gdprobligatoriu')]"
            :label="$t('gdprlabel')"
          />

          <v-row justify="center" style="margin-top: 20px">
            <LoaderButton
              :loading="loading2"
              :disabled="loading2"
              @click.native="upload"
              :label="$t('trimiteraportul')"
              :loadingLabel="$t('generamraportul')"
            />
          </v-row>
        </v-form>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import MGMinstructions from "../../components/MGM15/MGMinstructions.vue";
import Input from "../../components/inputs/Input.vue";
import Dropdown from "../../components/inputs/Dropdown.vue";
import MGMdateInput from "../../components/MGM15/MGMdateInput.vue";
import MGMselectWithOthers from "../../components/MGM15/MGMselectWithOthers.vue";
import MGMoptojumpRadios from "../../components/MGM15/MGMoptojumpRadios.vue";
import MGMupload from "../../components/MGM15/MGMupload.vue";
import MGMGDPR from "../../components/MGM15/MGMGDPR.vue";
import LoaderButton from "../../components/inputs/LoaderButton.vue";
import constants from "../../utils/constants.js";
import { HTTP } from "../../utils/http-common";
import clientStorage from "../../store/clientStorage";
export default {
  components: {
    MGMinstructions,
    Input,
    Dropdown,
    MGMdateInput,
    MGMoptojumpRadios,
    MGMselectWithOthers,
    MGMupload,
    MGMGDPR,
    LoaderButton,
  },
  name: "MGM",
  data: () => ({
    name: null,
    surname: null,
    weight: null,
    height: null,
    gender: null,
    birthday: null,
    genders: constants.genders,
    sport: null,
    sports: constants.MGMSports,
    variant: 0,
    opto_xml: null,
    GDPRcheckbox: null,
    athlete: null,
    valid: true,
    loading2: false,
    locale: clientStorage.getAppLanguage(),
  }),
  methods: {
    upload() {
      if (this.$refs.form.validate()) {
        let formData = new FormData();
        formData.append("name", this.name);
        formData.append("surname", this.surname);
        formData.append("gender", this.gender);
        formData.append("birthday", this.birthday);
        formData.append("weight", this.weight);
        formData.append("height", this.height);
        formData.append("sport", this.sport);
        formData.append("variant", this.variant);
        formData.append("xml", this.opto_xml);
        if (this.athlete != null && this.athlete !== "") {
          formData.append("athlete", this.athlete);
          let formDataVerify = new FormData();
          formDataVerify.append("athlete", this.athlete);
          this.verifyXML(formDataVerify, formData);
        } else {
          this.sendXMLToServer(formData);
        }
        this.loading2 = true;
      }
    },
    sendXMLToServer(formData) {
      HTTP.post("MGM/upload", formData, {
        responseType: "blob",
      }).then((resp) => {
        this.loading2 = false;
        if (
          resp["status"] == 200 &&
          resp["data"]["type"] == "application/pdf"
        ) {
          const blob = new Blob([resp["data"]]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob); //create the download url
          const data = this.opto_xml["name"].split("_");
          link.download = `rezultat-${data[6]}/${data[7]}/${data[8]}-${this.name} ${this.surname}.pdf`; //the name of the downloaded file
          // document.body.appendChild(link);
          link.click(); //click to file
          // document.body.removeChild(link); //remove the element
          URL.revokeObjectURL(link.href); //release the object  of the blob
          window.location.reload();
        } else if (
          resp["status"] == 200 &&
          resp["data"]["type"] == "application/json"
        ) {
          alert("Fonduri insuficiente");
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
    verifyXML(formDataVerify, formData) {
      HTTP.post("MGM/verify_upload", formDataVerify, {
        responseType: "json",
      }).then((resp) => {
        if (resp["data"]["status"]["code"] == 200) {
          this.sendXMLToServer(formData);
        } else if (resp["data"]["status"]["code"] != 200) {
          alert(resp["data"]["status"]["message"]);
          this.loading2 = false;
        } else {
          alert("A aparut o eroare interna!");
          this.loading2 = false;
        }
      });
    },
  },
};
</script>

<style></style>
