<template>
  <v-row justify="center">
    <v-col md="6">
      <v-card
        @click="clickUpload"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        :class="{ 'grey lighten-2': dragover }"
      >
        <v-card-text>
          <v-row
            class="d-flex flex-column"
            dense
            align="center"
            justify="center"
          >
            <v-icon :class="[dragover ? 'mt-2, mb-6' : 'mt-5']" size="60">
              mdi-cloud-upload
            </v-icon>
            <p>
              Urcaţi fişierul XML generat de platforma optojump, trăgând fişierul
              aici sau apăsând pe chenar pentru a deschide managerul de fişiere
              *
            </p>
          </v-row>
          <v-virtual-scroll
            v-if="uploadedFiles.length > 0"
            :items="uploadedFiles"
            height="150"
            item-height="50"
          >
            <template v-slot:default="{ item }">
              <v-list-item :key="item.name">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                    <span class="ml-3 text--secondary">
                      {{ item.size }} bytes</span
                    >
                  </v-list-item-title>
                </v-list-item-content>

                <v-list-item-action>
                  <v-btn @click.stop="removeFile(item.name)" icon>
                    <v-icon> mdi-close-circle </v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>

              <v-divider></v-divider>
            </template>
          </v-virtual-scroll>
        </v-card-text>
        <v-card-actions>
          <v-row justify="center">
            <input
              style="display: none"
              type="file"
              id="file"
              ref="file"
              v-on:change="attachUploadedFile(1)"
            />
          </v-row>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "MGMupload",
  data: () => ({
    dragover: false,
    uploadedFiles: [],
  }),
  methods: {
    clickUpload() {
      this.$refs.file.click();
    },
    onDrop(e) {
      this.dragover = false;
      this.attachUploadedFile(e, 2);
    },
    attachUploadedFile(e, method) {
      // If there are already uploaded files remove them
      if (this.uploadedFiles.length > 0) this.uploadedFiles = [];
      // If File is dragged
      if (method == 2) {
        // If user has uploaded multiple files but the component is not multiple throw error
        if (!this.multiple && e.dataTransfer.files.length > 1) {
          alert("Un singur fisier poate fi incarcat");
          this.$store.dispatch("addNotification", {
            message: "Un singur fisier poate fi incarcat",
            colour: "error",
          });
        }
        // Add each file to the array of uploaded files
        else {
          // e.dataTransfer.files.forEach((element) =>
          //   this.uploadedFiles.push(element)
          // );
          this.uploadedFiles.push(e.dataTransfer.files[0]);
          this.$emit("input", e.dataTransfer.files[0]);
        }
      }
      //If File is selected
      else {
        this.uploadedFiles.push(this.$refs.file.files[0]);
        this.$emit("input", this.$refs.file.files[0]);
      }
    },
    removeFile(fileName) {
      // Find the index of the
      const index = this.uploadedFiles.findIndex(
        (file) => file.name === fileName
      );
      // If file is in uploaded files remove it
      if (index > -1) this.uploadedFiles.splice(index, 1);
    },
  },
  mounted() {
    this.multiple = false;
  },
};
</script>
