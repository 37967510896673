<template>
  <v-dialog v-model="dialogInstructions" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        id="no-background-hover"
        color="info"
        elevation="0"
        v-bind="attrs"
        v-on="on"
      >
        Instrucţiuni de utilizare
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="text-h5 grey lighten-2"> INSTRUCŢIUNI </v-card-title>

      <v-card-text>
        Sistemul de evaluare a sportivilor propus de Neuromotrica se bazează pe
        culegerea datelor din testările realizare cu ajutorul platformei
        OptoJump și interpretarea acestora utilizând Metoda MGM-15 (Miron
        Georgescu, Modificată de Pierre J. de Hillerin), ce oferă informații
        importante atât sportivilor, antrenorilor, medicilor cât și
        preparatorilor fizici, informații ce pot ajuta la construirea unui
        program de pregătire personalizat nevoilor fiecărui sportiv.
        <br /><br />Mai departe vă prezentăm pașii necesari a fi parcurși pentru
        obținerea raportului de evaluare al sportivului:<br /><br />
        <u>⦁ Efectuarea testării utilizând platforma OptoJump</u><br /><br />
        Prezentul raport se adresează testării <b>15 SEC. JUMPS</b> ce implică
        sărituri continue, ca mingea, în interiorul barelor platformei
        OptoJump.<br /><br />
        Persoana care testează sportivul trebuie să acorde atenție ca sportivul
        să efectueze săriturile cu un timp de contact cu solul cât mai mic și cu
        o înălțime a săriturii cât mai mare.<br /><br />
        Protocolul ce trebuie respectat în vederea obținerii raportului corect
        este de a testa sportivul în următoarea ordine:<br /><br />
        ⦁ Sărituri timp de 15 secunde pe ambele picioare; <br /><br />⦁ Sărituri
        timp de 15 secunde pe piciorul drept; <br /><br />⦁ Sărituri timp de 15
        secunde pe piciorul stâng. <br /><br />Între fiecare dintre cele 3 probe
        se va oferi un timp de odihnă sportivului. <br /><br /><u
          >⦁ Obținerea fișierului ce conține datele înregistrate la cele 3
          probe</u
        ><br /><br />
        Pentru generarea raportului oferit de Neuromotrica este necesară
        obținerea raportului .xml din programul OptoJump ce conține datele
        înregistrate de sportiv in cele 3 probe realizate.<br /><br />
        Pentru a obține acest fișier accesați butonul RESULTS în programul
        OptoJump.<br /><br />
        În tabelul <b>TEST LIST</b> veți regăsi toate testele realizate de dvs.
        până în prezent, în ordine cronologică, cel mai de sus fiind ultimul
        test realizat (în cazul în care nu ați modificat ordinea de afișare).<br /><br />
        Pentru fiecare sportiv testat veți regăsi 3 rânduri cu numele acestuia,
        fiecare rând reprezentând probele din cadrul testului
        <b> 15 SEC. JUMPS.</b><br /><br />
        Rezultatele acestor 3 probe sunt afișate în ordine cronologică, iar dacă
        s-a respectat protocolul de testare, cel mai vechi test reprezintă
        testul de sărituri pe ambele picioare, următorul test reprezintă
        săriturile pe piciorul drept, iar cel mai nou test reprezintă săriturile
        pe piciorul stâng.<br /><br />
        Vă recomandăm să verificați ora și minutul afișate în coloana
        <b>Date</b> pentru a identifica ordinea probelor.<br /><br />
        <v-img :src="require('@/assets/i1.png')"></v-img>
        <br /><br />După identificarea ordinii probelor, se selectează mai întâi
        randul cu proba de sărituri pe ambele picioare și se mută rezultatul în
        tabelul TEST ANALYSIS prin apăsarea săgeții din dreptul acestei probe.
        <br /><br />După apăsarea săgeții, rezultatele acestui test se mută în
        tabelul TEST ANALYSIS.<br /><br />
        Repetați acest proces pentru rezultatele săriturilor pe piciorul drept,
        iar apoi pentru piciorul stâng. <br /><br />După mutarea celor 3 probe
        în tabelul TEST ANALYSIS vă rugăm să verificați încă o dată faptul că
        testul de sărituri pe ambele picioare este de această dată prima în
        tabelul TEST ANALYSIS, pe rândul al doilea se află testul de sărituri pe
        piciorul drept, iar pe al treilea rând se află testul de sărituri pe
        piciorul stâng. Acest lucru se poate verifica tot în coloanal Date, în
        funcție de timpul la care s-au realizat. Cea mai veche testare
        reprezintă săriturile pe ambele picioare, urmat de testul pe piciorul
        drept, iar cea mai nouă testare reprezintă săriturile pe piciorul
        stâng.<br /><br />
        După mutarea celor 3 probe ale testului în tabelul TEST ANALYSIS si
        verificarea corectitudinii ordinii acestora, se va exporta fișierul ce
        conține datele înregistrate la probe.<br /><br />
        Exportarea se realizează apăsând butonul <b>EXPORT</b>, aflat în colțul
        din dreapta-jos al ecranului.<br /><br />
        Se va selecta <b>Excel/XML extended</b> și se va salva fișierul în
        locația dorită din calculator. <br /><br />
        <v-img :src="require('@/assets/i2.png')"></v-img><br /><br />
        <u
          >⦁ Procesarea fișierului în platforma Neuromotrica și obținerea
          raportului personalizat</u
        ><br /><br />
        Pentru obținerea raportului personalizat al sportivului, fișierul XML
        salvat în calculator va fi încărcat în platforma noastră.<br /><br />
        Pentru personalizarea raportului, alături de încărcarea fișierului este
        necesară completarea datelor sportivului. Acestea vor fi afișate pe
        prima pagină a raportului.<br /><br />
        De asemenea, aveți opțiunea de a încărca sigla dvs., iar aceasta va fi
        afișată în header-ul raportului generat.<br /><br />Vă asigurăm că
        datele introduse sunt strict confidențiale și nu vor fi divulgate altor
        terți.<br /><br />
        Numele și prenumele sportivului va fi afișat pe raportul generat, însă
        stocarea lui ulterioară va fi criptată, asigurând anonimatul datelor
        introduse.<br /><br />
        Datele introduse vor fi utilizate cu scopul dezvoltării raportului de
        evaluare și personalizarea acestuia pe gen, vârstă și disciplină
        sportivă, dar și în scopuri științifice, pentru dezvoltarea
        performanțelor sportive.<br /><br />
        Pentru mai multe detalii legate de protecția datelor puteți accesa: GDPR
        din partea de jos a paginii<br /><br />
        Vă mulțumim!<br /><br />
        Pentru suport în utilizarea sistemului nostru ne puteți contacta la:<br /><br />
        e-mail: contact@neuromotrica.ro<br /><br />
        Telefon: 0372 916 480 <br /><br />Program: Luni – Vineri (09:00 – 19:00)
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialogInstructions = false">
          Ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "MGMinstructions",
  data: () => ({
    dialogInstructions: null,
  }),
};
</script>
