<template>
  <div>
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="9" lg="9" md="9" sm="12">
            <v-card class="mx-auto" outlined elevation="2">
              <v-card-title>
                Listă de testări
                <v-spacer />

                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Caută un raport.."
                  single-line
                  hide-details
                ></v-text-field>
              </v-card-title>

              <v-data-table
                :headers="headers"
                :items="raports"
                :search="search"
                class="elevation-1"
                multi-sort
                item-key="Id"
                dense
                no-data-text="Niciun raport disponibil"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-arrow-left',
                  nextIcon: 'mdi-arrow-right',
                  'items-per-page-text': 'Rapoarte pe pagină',
                  'items-per-page-options': [20, 50, 100],
                }"
              >
                <template v-slot:[`item.selected`]="{ item }">
                  <v-simple-checkbox
                    color="blue"
                    v-model="item.selected"
                    :ripple="false"
                  ></v-simple-checkbox>
                </template>

                <template v-slot:[`item.actiuni`]="{ item }">
                  <!-- <v-tooltip bottom dark>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="editItem(item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                      
                    </template>
                    <span>Editează raportul</span>
                  </v-tooltip> -->

                  <v-tooltip bottom dark>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="DownloadPDF(item)"
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                        color="primary"
                      >
                        mdi-download
                      </v-icon>
                    </template>
                    <span>Descărca raportul PDF</span>
                  </v-tooltip>

                  <!-- <v-tooltip bottom dark>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click="DownloadPDF(item)"
                        v-bind="attrs"
                        v-on="on"
                        class="ml-2"
                        color="red"
                      >
                        mdi-delete
                      </v-icon>
                      
                    </template>
                    <span>Şterge raportul PDF</span>
                  </v-tooltip> -->
                </template>
              </v-data-table>
            </v-card>
          </v-col>

          <v-col class="coloana" cols="3" lg="3" md="3" sm="12">
            <div class="sticky-top">
              <v-card class="mx-auto" outlined max-width="90%" elevation="1">
                <v-card-title class="text-h5" style="word-break: break-word">
                  Raport centralizator
                </v-card-title>

                <v-card-text>
                  Acest raport permite selectarea unui număr nelimitat de
                  sportivi pentru generarea raportului.
                </v-card-text>

                <v-card-actions class="justify-center">
                  <LoaderButton
                    :loading="loading2"
                    :disabled="loading2"
                    @click.native="getRaport"
                    label="Generare"
                    loadingLabel="Prelucrare..."
                    width="150"
                  />
                </v-card-actions>
              </v-card>

              <v-card
                class="mx-auto"
                max-width="90%"
                outlined
                style="margin-top: 20px"
                elevation="1"
              >
                <v-card-title class="text-h5" style="word-break: break-word">
                  Raport comparativ
                </v-card-title>

                <v-card-text>
                  Selectează două evaluări ale aceluiași sportiv pentru a genera
                  un raport comparativ.
                </v-card-text>

                <v-card-actions class="justify-center">
                  <LoaderButton
                    :loading="loading3"
                    :disabled="loading3"
                    @click.native="getRaportComparativ"
                    label="Generare"
                    loadingLabel="Prelucrare..."
                    width="150"
                  />
                </v-card-actions>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="500px" persistent>
          <v-card>
            <v-card-title>
              <span class="text-h5">Editează raport</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="6">
                    <Input
                      v-model="editedItem.Name"
                      :label="$t('numesportiv')"
                      :rules="[(v) => !!v || $t('numeobligatoriu')]"
                      style="padding-bottom: 0 !important"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <Input
                      v-model="editedItem.Id"
                      :label="$t('idsportiv')"
                      style="padding-bottom: 0 !important"
                    />
                  </v-col>

                  <v-col cols="12" sm="6" md="6">
                    <v-menu
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="editedItem.Date"
                          :rules="[
                            (v) => !!v || $t('dataantrenamentuluiobligatorie'),
                          ]"
                          :label="$t('dataantrenamentului')"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          outlined
                          dense
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="editedItem.Date"
                        @input="menu2 = false"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6" md="6">
                    <v-select
                      v-model="editedItem.Sport"
                      dense
                      outlined
                      :label="$t('selecteazasportul')"
                      :items="sports"
                      :rules="[(v) => !!v || $t('sportobligatoriu')]"
                    ></v-select>

                    <v-text-field
                      v-model="otherSport"
                      v-if="editedItem.Sport == 'Altul'"
                      :rules="[(v) => !!v || 'Completati acest camp!']"
                      color="blue"
                      label="Sport *"
                      required
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="close">
                Anulează
              </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Salvează </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
    </v-main>
  </div>
</template>

<script>
import LoaderButton from "../../../components/inputs/LoaderButton.vue";
import { HTTP } from "../../../utils/http-common";
import Input from "../../../components/inputs/Input.vue";
import constants from "../../../utils/constants.js";
export default {
  name: "MGMStatistics",
  components: {
    LoaderButton,
    Input,
  },
  data: () => ({
    search: "",
    dialog: false,
    editedIndex: -1,
    editedItem: {},
    loading2: false,
    loading3: false,
    sports: constants.MGMSports,
    otherSport: "",
    menu2: false,
    raports: [],
    headers: [
      { text: "Selectează", value: "selected", sortable: false },
      { text: "ID", value: "Id" },
      { text: "Nume", value: "Name" },
      { text: "Data", value: "Date" },
      { text: "Sport", value: "Sport" },
      { text: "Acţiuni", value: "actiuni", sortable: false },
    ],
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  mounted() {
    HTTP.get("MGM/user", {
      responseType: "json",
    }).then((resp) => {
      if (resp["data"]["status"]["code"] == 200) {
        this.raports = resp["data"]["data"];
      } else if (resp["data"]["status"]["code"] != 200) {
        alert("S-a produs o eroare interna");
        this.$router.push("/");
      } else {
        alert("S-a produs o eroare interna");
        this.$router.push("/");
      }
    });
  },
  methods: {
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedItem = 0;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.raports[this.editedIndex], this.editedItem);
      } else {
        this.raports.push(this.editedItem);
      }
      this.close();
    },

    editItem(item) {
      console.log(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
      //this.editedItem = {...item};
    },

    getRaport() {
      var ids = [];
      this.loading2 = true;
      for (const e of this.raports) {
        if (e.selected == true) ids.push(e.Id);
      }
      let formData = new FormData();
      formData.append("ids", ids.toString());
      HTTP.post("MGM/centralized_statistics", formData, {
        responseType: "blob",
      }).then((resp) => {
        if (
          resp["status"] == 200 &&
          resp["data"]["type"] == "application/pdf"
        ) {
          const blob = new Blob([resp["data"]]);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob); //create the download url
          link.download = `centralizator.pdf`; //the name of the downloaded file
          // document.body.appendChild(link);
          link.click(); //click to file
          // document.body.removeChild(link); //remove the element
          URL.revokeObjectURL(link.href); //release the object  of the blob
          // window.location.reload();
        } else if (
          resp["status"] == 200 &&
          resp["data"]["type"] == "application/json"
        ) {
          this.loading3 = false;
          alert("Fonduri insuficiente");
        } else {
          alert("A aparut o eroare interna!");
        }
        this.loading2 = false;
      });
    },

    getRaportComparativ() {
      var ids = [];
      this.loading3 = true;
      for (const e of this.raports) {
        if (e.selected == true) ids.push(e.Id);
      }

      if (ids.length == 2) {
        let formData = new FormData();
        formData.append("ids", ids.toString());
        HTTP.post("MGM/comparative_statistics", formData, {
          responseType: "blob",
        }).then((resp) => {
          if (
            resp["status"] == 200 &&
            resp["data"]["type"] == "application/pdf"
          ) {
            const blob = new Blob([resp["data"]]);
            const link = document.createElement("a");
            for (const e of this.raports) {
              if (e.Id == ids[0]) {
                this.name = e.Name;
                break;
              }
            }
            link.href = URL.createObjectURL(blob); //create the download url
            link.download = `Raport comparativ-${this.name}.pdf`; //the name of the downloaded file
            // document.body.appendChild(link);
            link.click(); //click to file
            // document.body.removeChild(link); //remove the element
            URL.revokeObjectURL(link.href); //release the object  of the blob
            // window.location.reload();
            this.loading3 = false;
          } else if (
            resp["status"] == 200 &&
            resp["data"]["type"] == "application/json"
          ) {
            this.loading3 = false;
            alert("Fonduri insuficiente");
          } else {
            this.loading3 = false;
            alert("A aparut o eroare interna!");
          }
        });
      } else {
        alert(
          "Această funcţie va genera un raport doar dacă ai ales 2 antrenamente din tabelul afişat."
        );
        this.loading3 = false;
      }
    },
    DownloadPDF(item) {
      console.log(item);
      let URL = "MGM/download?id=" + item.Id;
      HTTP.get(URL, {
        responseType: "blob",
      }).then((resp) => {
        if (
          resp["status"] == 200 &&
          resp["data"]["type"] == "application/pdf"
        ) {
          const blob = new Blob([resp["data"]]);
          const link = document.createElement("a");
          link.href = global.URL.createObjectURL(blob); //create the download url
          link.download = `Raport-${item.Date}-${item.Name}.pdf`; //the name of the downloaded file
          // document.body.appendChild(link);
          link.click(); //click to file
          // document.body.removeChild(link); //remove the element
          URL.revokeObjectURL(link.href); //release the object  of the blob
          // window.location.reload();
        } else {
          alert("A aparut o eroare interna!");
        }
      });
    },
  },
};
</script>

<style scoped>
.sticky-top {
  position: fixed;
}

.v-text-field {
  padding-top: 0;
  margin-top: 0;
}

.coloana {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
