<template>
  <v-container fluid class="body">
    <v-form ref="form" lazy-validation v-model="valid">
      <v-card 
      rounded
      raised
      class="form"
      >
        <logo />
        <p style="color: #202124; font-size: 20px; font-weight: 400; margin-bottom: 20px;">Creaţi-vă contul</p>
        <v-text-field
          v-model="email"
          :rules="[rules.required, rules.email]"
          label="Introduceţi adresa de e-mail"
        ></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.strong]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Introduceţi o parolă sigură"
          @click:append="show1 = !show1"
        ></v-text-field>
        <v-text-field
          v-model="password2"
          :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, passwordConfirmationRule, rules.strong]"
          :type="show1 ? 'text' : 'password'"
          name="input-10-1"
          label="Repetaţi parola"
          @click:append="show1 = !show1"
        ></v-text-field>

        <div class ="bottom-row">
          <v-btn color="primary" 
          depressed 
          outlined 
          dark 
          router-link :to="'/'">Înapoi</v-btn>

          <v-btn color="primary" dark @click="register">Înainte</v-btn>
        </div>

      </v-card>
    </v-form>
  </v-container>
</template>

<script>
import logo from "../../components/logo.vue";
import { HTTP } from "../../utils/http-common";
export default {
  components: { logo },
  name: "register",
  data: () => ({
    valid: true,
    email: null,
    password: null,
    password2: null,
    show1: false,
    rules: {
      required: (value) => !!value || "Acest câmp este obligatoriu",
      strong: (value) =>
        (value &&
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#])[A-Za-z\d@$!%*?&.#]{6,}$/.test(
            value
          )) ||
        "Parola trebuie să conţină minim 6 caractere, o literă mare, un caracter special şi un număr",
      email: (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "Adresa de e-mail trebuie să fie validă",
    },
  }),
  methods: {
    register() {
      this.$refs.form.validate();
      if (this.valid) {
        let formData = new FormData();
        formData.append("email", this.email);
        formData.append("password", this.password);
        formData.append("password2", this.password2);
        HTTP.post("register", formData, {
          responseType: "json",
        }).then((resp) => {
          if (resp["data"]["status"]["code"] == 200) {
            alert(
              "Contul dumneavoastră a fost înregistrat cu succes. Pentru a activa contul, verificaţi adresa de e-mail"
            );
            this.$router.push("/");
          } else if (resp["data"]["status"]["code"] != 200) {
            alert(resp["data"]["status"]["message"]);
          } else {
            alert("A aparut o eroare internă!");
          }
        });
      }
    },
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.password === this.password2 || "Cele 2 parole nu coincid";
    },
  },
};
</script>

<style scoped>
.form {
  padding: 48px 40px;
  text-align: center;
  width: 448px;
}

.body {
  background-color: rgb(145, 198, 216);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-row{
  display: flex;
   justify-content: space-between;
   margin-top: 20px;
}
</style>
