import VueRouter from "vue-router";
import Auth from "../views/Auth/login.vue"
import Register from "../views/Auth/register.vue"
import Confirm from "../views/Auth/confirm.vue"
import MGM from "../views/Apps/mgm15.vue"
import RecoverPass from "../views/Auth/recoverPass.vue"
import ResetPass from "../views/Auth/resetPass.vue"
import UserProfile from "../views/Profile/userProfile.vue"
import Admin from "../views/Apps/admin.vue"
import MGMGlobalCentralizedStatistics from "../views/Apps/MGMResearchApps/MGMGlobalCentralizedStatistics.vue"
import MGMStatistics from "../views/Apps/MGMResearchApps/MGMStatistics.vue"
import clientStorage from "../store/clientStorage";
import NotFound from "../views/NotFoundPage.vue"
import Pricing from "../views/Cart/Pricing.vue"
import OrderSuccess from "../views/Cart/OrderSuccess.vue"
import OrderCanceled from "../views/Cart/OrderCanceled.vue"

const routes = [
  {
    path: "/",
    component: Auth,
    name: "Login",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() == null) {
        next()
      }
      else {
        next({ name: "MGM" })
      }
    }
  },
  {
    path: "/register",
    component: Register,
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() == null) {
        next()
      }
      else {
        next({ name: "MGM" })
      }
    }
  },
  {
    path: "/confirm",
    component: Confirm,
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() == null) {
        next()
      }
      else {
        next({ name: "MGM" })
      }
    }
  },
  {
    path: "/mgm15",
    component: MGM,
    name: "MGM",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() != null) {
        next()
      }
      else {
        alert("Sesiunea s-a terminat, reintrati in cont pentru continua")
        next({ name: "Login" })
      }
    }
  },
  {
    path: "/recover_pass",
    component: RecoverPass,
    name: "Recover Password",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() == null) {
        next()
      }
      else {
        next({ name: "MGM" })
      }
    }
  },
  {
    path: "/reset_pass",
    component: ResetPass,
    name: "Reset Password",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() == null) {
        next()
      }
      else {
        next({ name: "MGM" })
      }
    }
  },
  {
    path: "/user_profile",
    component: UserProfile,
    name: "UserProfile",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() != null) {
        next()
      }
      else {
        alert("Sesiunea s-a terminat, reintrati in cont pentru continua")
        next({ name: "Login" })
      }
    }
  },
  {
    path: "/admin",
    component: Admin,
    name: "Admin",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() != null && clientStorage.getUser().role.Name == 'Admin') {
        next()
      }
      else {
        alert("Sesiunea s-a terminat, reintrati in cont pentru continua")
        next({ name: "Login" })
      }
    }
  },
  {
    path: "/mgm_global_centralized_statistics",
    component: MGMGlobalCentralizedStatistics,
    name: "MGMGlobalCentralizedStatistics",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() != null && clientStorage.getUser().role.Name != 'Utilizator') {
        next()
      }
      else {
        alert("Sesiunea s-a terminat, reintrati in cont pentru continua")
        next({ name: "Login" })
      }
    }
  },

  {
    path: "/mgm_statistics",
    component: MGMStatistics,
    name: "MGMStatistics",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() != null) {
        next()
      }
      else {
        alert("Sesiunea s-a terminat, reintrati in cont pentru continua")
        next({ name: "Login" })
      }
    }
  },

  {
    path: "/pricing",
    component: Pricing,
    name: "Pricing",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() != null) {
        next()
      }
      else {
        alert("Sesiunea s-a terminat, reintrati in cont pentru continua")
        next({ name: "Login" })
      }
    }
  },
  {
    path: "/success",
    component: OrderSuccess,
    name: "OrderSuccess",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() != null) {
        next()
      }
      else {
        alert("Sesiunea s-a terminat, reintrati in cont pentru continua")
        next({ name: "Login" })
      }
    }
  },
  {
    path: "/canceled",
    component: OrderCanceled,
    name: "OrderCanceled",
    beforeEnter: (to, from, next) => {
      if (clientStorage.getToken() != null) {
        next()
      }
      else {
        alert("Sesiunea s-a terminat, reintrati in cont pentru continua")
        next({ name: "Login" })
      }
    }
  },
  {
    path: "*",
    component: NotFound,
    name: "NotFound"
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;