<template>
  <div>
    <v-app-bar color="blue" dense dark app clipped-left fixed>
      <v-app-bar-nav-icon @click = Hamburger()></v-app-bar-nav-icon>
      <v-app-bar-title>Neuromotrica</v-app-bar-title>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "navbar",
  methods: {
    Hamburger(){
      this.$root.$emit('DeschideHamburger')
    }
  }
};
</script>
